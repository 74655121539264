import { useNavigate } from 'react-router-dom';
import Column from 'components/Column';
import { limitChars, abbreviateNum } from 'hooks/utils';
import {
  Container,
  Thumb,
  StyledImage,
  TopLabel,
  Label,
  InfoContainer,
  InfoTitle,
  InfoDescription,
} from './styled';

export const CuadroCard = ({ cuadro }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/detail/${cuadro.id}`);
  };

  return (
    <Container onClick={handleClick}>
      <Thumb>
        <StyledImage src={require(`../../../assets/img/cuadros/cuadro_${cuadro.id}.jpg`)} />
        {cuadro.label && (
          <TopLabel $justifyContent="space-between">
            <Label>{cuadro.label}</Label>
          </TopLabel>
        )}
        <InfoContainer $justifyContent="space-between">
          <Column>
            <InfoTitle>{limitChars(cuadro.name, 16)}</InfoTitle>
            <InfoDescription
              dangerouslySetInnerHTML={{ __html: limitChars(cuadro.description, 100) }}
            />
          </Column>
          <InfoDescription>U$D{abbreviateNum(cuadro.price)}</InfoDescription>
        </InfoContainer>
      </Thumb>
    </Container>
  );
};
