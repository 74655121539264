import { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Button } from 'components/Button';
import Icon, { IconNames } from 'components/Icon';
import { BodySmall, H3, H4 } from 'components/Typography';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { abbreviateNum } from 'hooks/utils';
import { getCuadrosState } from 'selectors/cuadros';
import { clearCartCuadros, getCartCuadros, setCartCuadros } from 'services/storage';
import { getCuadros } from 'store/cuadros/actions';
import { MotionDiv, CartContainer, CartItem, StyledImage } from './styled';

interface Props {
  isOpen: boolean;
  closeCart: () => void;
  iconRef: any;
}

function useOutsideAlerter(ref, closeCart, iconRef) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !iconRef.current.contains(event.target)
      ) {
        closeCart();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export const SlidingCart: FC<Props> = ({ isOpen, closeCart, iconRef }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<any>(null);
  const cuadrosState = useAppSelector(getCuadrosState);

  useEffect(() => {
    if (!cuadrosState.data) dispatch(getCuadros());
  }, []);

  useOutsideAlerter(wrapperRef, closeCart, iconRef);
  const currentArrCartCuadros = getCartCuadros()?.split(',') || [];
  const cartItems = cuadrosState.data?.filter(item =>
    currentArrCartCuadros.includes(item.id.toString())
  );

  const variants = {
    open: () => ({
      clipPath: 'inset(0 0 0% 0%)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    }),
    closed: {
      clipPath: 'inset(0 0 100% 100%)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const handleClick = (id: number) => {
    navigate(`/detail/${id}`);
    closeCart();
  };

  const removeFromCart = (id: number) => {
    const arr = currentArrCartCuadros?.filter(item => item !== id.toString());
    if (arr.toString()) setCartCuadros(arr.toString());
    else clearCartCuadros();
    window.location.reload();
  };

  return (
    <motion.nav ref={wrapperRef} initial={false} animate={isOpen ? 'open' : 'closed'}>
      <MotionDiv variants={variants}>
        <Row $justifyContent="space-between">
          <H3>Mi Carrito</H3>
          <Icon
            name={IconNames.XClose}
            color={theme.colors.matterhorn}
            size={32}
            style={{ cursor: 'pointer' }}
            onClick={closeCart}
          />
        </Row>
        <CartContainer>
          {currentArrCartCuadros.length ? (
            <>
              {cartItems?.map(item => (
                <CartItem key={item.id}>
                  <Row $justifyContent="space-between" $alignItems="center">
                    <Row $pressable onClick={() => handleClick(item.id)}>
                      <StyledImage
                        src={require(`../../assets/img/cuadros/cuadro_${item.id}.jpg`)}
                      />
                      <Spacing $vertical $size={12} />
                      <Column>
                        <H4>{item.name}</H4>
                        <BodySmall $color={theme.colors.gulfStream}>
                          U$D{abbreviateNum(parseInt(item.price))}
                        </BodySmall>
                      </Column>
                    </Row>
                    <Icon
                      name={IconNames.Trash}
                      color={theme.colors.errorRed}
                      size={18}
                      style={{ cursor: 'pointer' }}
                      onClick={() => removeFromCart(item.id)}
                    />
                  </Row>
                </CartItem>
              ))}
              <CartItem>
                <Row $justifyContent="space-between" $alignItems="center">
                  <H4>Subtotal</H4>
                  <BodySmall $color={theme.colors.gulfStream}>
                    U$D
                    {abbreviateNum(
                      cartItems?.reduce((sum, cuadro) => sum + parseInt(cuadro.price), 0)
                    )}
                  </BodySmall>
                </Row>
              </CartItem>
              <CartItem>
                <Row $justifyContent="space-between" $alignItems="center">
                  <H4>Total</H4>
                  <BodySmall $color={theme.colors.gulfStream}>
                    U$D
                    {abbreviateNum(
                      cartItems?.reduce((sum, cuadro) => sum + parseInt(cuadro.price), 0)
                    )}
                  </BodySmall>
                </Row>
              </CartItem>
              <Spacing $size={24} />
              <Button to="/checkout">Iniciar compra</Button>
            </>
          ) : (
            <>
              <Spacing $size={40} />
              <H4>El carrito de compras está vacío</H4>
            </>
          )}
        </CartContainer>
      </MotionDiv>
    </motion.nav>
  );
};
