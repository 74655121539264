import styled from 'styled-components';
import { H1 } from 'components/Typography';

export const HeaderVideo = styled.video`
  width: 100%;
  height: auto;
  align-self: flex-start;
  object-fit: fill;
`;

export const Title = styled(H1)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;

  @media (max-width: 800px) {
    font-size: 40px;
    line-height: 44px;
  }
`;

export const TextContainer = styled.div`
  padding: 16px 24px;
  max-width: 800px;
  margin: auto;
`;
