import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store/create-store';
import { CuadrosState, ICuadrosData } from 'store/cuadros/types';

export const getCuadrosState = (state: RootState): CuadrosState => state.cuadros;

export const getCuadrosData = createSelector(
  [getCuadrosState],
  (state: CuadrosState): ICuadrosData[] => state?.data?.filter(cuadro => cuadro.active)
);
