import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'config/theme';
import FontStyles from 'config/theme/fontStyles';
import store from 'store/create-store';
import { router } from 'navigation/router';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';
import 'index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <FontStyles />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
