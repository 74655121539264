import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Spacing from 'components/Spacing';
import { Button } from 'components/Button';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import Loader from 'components/Loader';
import { showToast, Toast } from 'components/Toast';
import Input, { onChangeTextareaType, onChangeType } from 'components/Input';
import { emailFormat } from 'helpers/regexp';
import { Container, FormContainer, Title, Subtitle } from './styled';

export const ContactScreen = () => {
  const form: any = useRef();
  const pageLoaderLoading = false;
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [consult, setConsult] = useState('');

  const handleSubmit = () => {
    if (name && surname && email && phone && consult) {
      emailjs
        .sendForm('service_eij9ist', 'template_pk5fiwc', form.current, '_guNqvHSvZ9NRyRBw')
        .then(error => console.log(error.text));
      showToast('Mensaje envíado exitosamente!', 'success', cleanForm);
    } else {
      console.log('Faltan datos');
    }
  };

  const cleanForm = () => {
    setName('');
    setSurname('');
    setEmail('');
    setPhone('');
    setConsult('');
  };

  return (
    <>
      {pageLoaderLoading && <Loader type="dots" />}
      <>
        <Header />
        <Toast />
        <Container>
          <Title>Contacto</Title>
          <Spacing $size={4} />
          <Subtitle>Si tenes alguna consulta, dejá tus datos y nos pondremos en contacto!</Subtitle>
          <FormContainer ref={form}>
            <Input
              id="name"
              name="name"
              placeholder="Nombre"
              value={name}
              textOnChange={(e: onChangeType) => setName(e.target.value)}
              fullWidth
            />
            <Spacing $size={16} />
            <Input
              id="surname"
              name="surname"
              placeholder="Apellido"
              value={surname}
              textOnChange={(e: onChangeType) => setSurname(e.target.value)}
              fullWidth
            />
            <Spacing $size={16} />
            <Input
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              textOnChange={(e: onChangeType) => setEmail(e.target.value)}
              fullWidth
              withErrors={!emailFormat.test(email) && !!email}
              leyend={!emailFormat.test(email) && !!email ? 'El email ingresado no es válido.' : ''}
            />
            <Spacing $size={16} />
            <Input
              id="phone"
              name="phone"
              placeholder="Teléfono"
              value={phone}
              textOnChange={(e: onChangeType) => setPhone(e.target.value)}
              fullWidth
            />
            <Spacing $size={16} />
            <Input
              id="consult"
              name="consult"
              placeholder="Consulta"
              value={consult}
              textareaOnChange={(e: onChangeTextareaType) => setConsult(e.target.value)}
              fullWidth
              textarea
            />
          </FormContainer>
          <Button onClick={handleSubmit}>Envíar</Button>
        </Container>
        <Footer />
      </>
    </>
  );
};
