import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spacing from 'components/Spacing';
import { Button } from 'components/Button';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { abbreviateNum } from 'hooks/utils';
import Loader from 'components/Loader';
import { Row, RowToGrid } from 'components/Row';
import { clearCartCuadros, getCartCuadros, setCartCuadros } from 'services/storage';
import { Container, StyledImage, Content, Title, Description, Price, ColumnImages } from './styled';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getCuadrosState } from 'selectors/cuadros';
import { getCuadros } from 'store/cuadros/actions';
import { useDomEvent, motion } from 'framer-motion';

export const DetailScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentCartCuadros = getCartCuadros();
  const cuadrosState = useAppSelector(getCuadrosState);
  const currentArrCartCuadros = currentCartCuadros?.split(',') || [];
  const [isOpen, setOpen] = useState(false);
  const [imageExpanded, setImageExpanded] = useState<string | undefined>('');

  useDomEvent(useRef(window), 'scroll', () => isOpen && setOpen(false));

  useEffect(() => {
    if (!cuadrosState.data) dispatch(getCuadros());
  }, []);

  const cuadro = cuadrosState.data?.find(cuadro => cuadro.id === Number(id));

  useEffect(() => {
    if (!cuadro && !cuadrosState.isLoading && cuadrosState.data) navigate('/');
    else setImageExpanded(id);
  }, [cuadro]);

  const addToCart = () => {
    setCartCuadros(currentCartCuadros ? `${currentCartCuadros},${id}` : `${id}`);
    window.location.reload();
  };

  const removeFromCart = () => {
    const arr = currentArrCartCuadros?.filter(item => item !== id);
    if (arr.toString()) setCartCuadros(arr.toString());
    else clearCartCuadros();
    window.location.reload();
  };

  const transition = {
    type: 'spring',
    damping: 25,
    stiffness: 120,
  };

  return (
    <>
      {cuadrosState.isLoading && <Loader type="dots" />}
      <>
        <Header />
        <Container>
          <RowToGrid $switchToGrid={700}>
            <ColumnImages>
              {imageExpanded && (
                <div className={`image-container ${isOpen ? 'open' : ''}`}>
                  <motion.div
                    animate={{ opacity: isOpen ? 1 : 0 }}
                    transition={transition}
                    className="shade"
                    onClick={() => setOpen(false)}
                  />
                  <StyledImage
                    src={require(`../../assets/img/cuadros/cuadro_${imageExpanded}.jpg`)}
                    alt="Cuadro"
                    onClick={() => setOpen(!isOpen)}
                  />
                </div>
              )}
              <Spacing $size={16} />
              {cuadro?.extra_images?.length && cuadro?.extra_images[0] ? (
                <Row $gap={8} $flexWrap="wrap">
                  <StyledImage
                    onClick={() => setImageExpanded(id)}
                    src={require(`../../assets/img/cuadros/cuadro_${id}.jpg`)}
                    $small
                  />
                  {cuadro.extra_images?.map((img_id, i) => (
                    <StyledImage
                      key={i}
                      onClick={() => setImageExpanded(`${id}${img_id}`)}
                      src={require(`../../assets/img/cuadros/cuadro_${id}${img_id}.jpg`)}
                      $small
                    />
                  ))}
                </Row>
              ) : null}
            </ColumnImages>
            <Content>
              {cuadro && (
                <>
                  <Title>{cuadro?.name}</Title>
                  <Price>U$D{abbreviateNum(parseInt(cuadro?.price || ''))}</Price>
                  <Spacing $size={16} />
                  <Description dangerouslySetInnerHTML={{ __html: cuadro?.description }} />
                  <Spacing $size={16} />
                  {!currentArrCartCuadros.includes(`${id}`) ? (
                    <Button onClick={addToCart}>Agregar al Carrito</Button>
                  ) : (
                    <Button onClick={removeFromCart}>Sacar del Carrito</Button>
                  )}
                </>
              )}
            </Content>
          </RowToGrid>
        </Container>
        <Footer />
      </>
    </>
  );
};
