import { FC, useEffect, useRef } from 'react';
import { H3 } from 'components/Typography';
import { CuadroCard } from 'components/Card/CuadroCard';
import useCuadrosPagination from 'hooks/useCuadrosPagination';
import { ISeriesData } from 'store/series/types';
import { ICuadrosData } from 'store/cuadros/types';
import { Container, ListCuadros } from './styled';

interface Props {
  serie: ISeriesData;
  cuadros: ICuadrosData[];
}

export const Serie: FC<Props> = ({ serie, cuadros }) => {
  const batchSize = 3;
  const [currentCuadros, loadNextBatch] = useCuadrosPagination(cuadros, batchSize);
  const listRef = useRef(null);
  const sentinelRef = useRef(null);

  useEffect(() => {
    const options = {
      root: listRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadNextBatch();
        }
      });
    }, options);

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [loadNextBatch]);

  return (
    <Container>
      <H3>{serie.title}</H3>
      <ListCuadros ref={listRef}>
        {currentCuadros
          .filter(cuadro => cuadro.id_serie === serie.id)
          .sort((a, b) => a.order - b.order)
          .map(cuadro => (
            <CuadroCard key={cuadro.id} cuadro={cuadro} />
          ))}
        <div ref={sentinelRef} style={{ width: '1px' }} />
      </ListCuadros>
    </Container>
  );
};
