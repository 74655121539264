import Column from 'components/Column';
import { Row } from 'components/Row';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
  justify-items: center;
`;

export const Content = styled(Row)`
  width: -webkit-fill-available;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const CartContainer = styled.div`
  width: 100%;
  max-width: 500px;
  padding-left: 40px;
  border-left: 1px solid ${({ theme }) => theme.colors.shaft};

  @media (max-width: 1000px) {
    max-width: 100%;
    border-left: none;
    padding: 0;
  }
`;

export const CheckoutForm = styled.form`
  display: grid;
  width: 100%;
  align-content: flex-start;
  justify-items: flex-start;
`;

export const HiddenContainer = styled.div<{ $showInDesktop: boolean }>`
  display: ${({ $showInDesktop }) => ($showInDesktop ? 'block' : 'none')};

  @media (max-width: 1000px) {
    display: ${({ $showInDesktop }) => ($showInDesktop ? 'none' : 'block')};
    width: -webkit-fill-available;
  }
`;
